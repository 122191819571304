import Vue, { ComponentOptions, AsyncComponent } from 'vue';
import { RouteConfig, NavigationGuard } from 'vue-router';
import Startpage from './pages/Startpage.vue';
import FAQ from './pages/FAQ.vue';
import Impressum from './pages/Impressum.vue';
import Contact from './pages/Contact/Contact.vue';
import ContactHolder from './pages/Contact/ContactHolder.vue';
import ContactSuccess from './pages/Contact/ContactSuccess.vue';
import Appointment from './pages/Appointment/Appointment.vue';
import AppointmentHolder from './pages/Appointment/AppointmentHolder.vue';
import AppointmentSelection from './pages/Appointment/AppointmentSelection.vue';
import AppointmentConfirmation from './pages/Appointment/AppointmentConfirmation.vue';
import AppointmentSummary from './pages/Appointment/AppointmentSummary.vue';
import Order from './pages/Order.vue';
import Tracking from './pages/Tracking.vue';
import Error from './pages/Error.vue';
import PrivacyNotice from './pages/PrivacyNotice.vue'
import GTC from './pages/GTC.vue';
import AdditionalServices from './pages/AdditionalServices.vue';
import store from '@/store/index';
import i18n from '@/i18n/index';

interface RouteItem {
	pathName: string; // The name of the route in the translation files
	name?: string; // The name of the component
	meta?: object;
	component: ComponentOptions<Vue> | typeof Vue | AsyncComponent;
	children?: Array<RouteItem>;
	alias?: string;
	beforeEnter?: NavigationGuard;
}

// Creates a link to the given route in the current language
export function getRouteName(routeKey: string): string {
	if (routeKey === "error") {
		// Don't set a locale for the error page
		return routeKey;
	}
	
	if(routeKey === "appointmentSelection" )
	{
		if ((store.state.service.hasAppointment || (!store.state.service.hasAppointment && !store.state.service.canMakeAppointment))) {
			return `${i18n.locale}.appointmentBase`;
		}
	}

	return `${i18n.locale}.${routeKey}`;
}

export function getDirectRouteName(routeKey: string): string {
	return `${i18n.locale}.${routeKey}`;
}

function redirectToHome(to: {}, from: {}, next: (to?: { name?: string }) => void) {
	const routeName = getRouteName("startpage");
	next({ name: routeName });
}

const allRoutes: Array<RouteItem> = [
	{
		pathName: '',
		name: 'startpage',
		component: Startpage,
		meta: {
			disallowAuth: true
		}
	},
	{
		pathName: 'faq',
		name: 'faq',
		component: FAQ,
	},
	{
		pathName: 'imprint',
		name: 'imprint',
		component: Impressum,
	},

	{
		pathName: 'contact',
		component: ContactHolder,
		children: [
			{
				pathName: 'contact',
				name: 'contact',
				component: Contact
			},
			{
				pathName: 'contact/thanks',
				name: 'contactSuccess',
				component: ContactSuccess
			}
		]
	},
	{
		pathName: 'orderinformation',
		name: 'order',
		component: Order,
		meta: {
			requiresAuth: true,
		}
	},
	{
		pathName: 'trackandtrace',
		name: 'tracking',
		component: Tracking,
		meta: {
			requiresAuth: true,
		}
	},
	{
		pathName: 'dataprotection',
		name: 'privacy',
		component: PrivacyNotice,
	},
	{
		pathName: 'appointment',
		component: AppointmentHolder,
		meta: {
			requiresAuth: true,
		},
		children: [
			{
				pathName: 'appointment',
				name: 'appointmentBase',
				component: Appointment,
			},
			{
				pathName: 'appointment/selection',
				name: 'appointmentSelection',
				component: AppointmentSelection
			},
			{
				pathName: 'appointment/confirmation',
				name: 'appointmentConfirmation',
				component: AppointmentConfirmation
			},
			{
				pathName: 'appointment/summary',
				name: 'appointmentSummary',
				component: AppointmentSummary
			},
			{
				pathName: 'appointment/gtc',
				name: 'gtc',
				component: GTC
			},
			{
				pathName: 'appointment/additionalservices',
				name: 'additionalServices',
				component: AdditionalServices,
			}
		]
	}
];

function convertChildRouteConfigToRoute(routeChild: RouteItem, languageCode: string): RouteConfig {
	return {
		path: `/${languageCode}/${routeChild.pathName}`,
		component: routeChild.component,
		meta: routeChild.meta,
		beforeEnter: routeChild.beforeEnter,
		name: `${languageCode}.${routeChild.name}`
	};
}

function convertRouteConfigToRoutes(routeItem: RouteItem): Array<RouteConfig> {
	// Take each configured config item and convert it into a format that the vue-router can use. One entry will be created for each 
	// language that the site is configured to support
	const allLanguageNames = i18n.availableLocales;
	const items = allLanguageNames.map(languageCode => {
		const item: RouteConfig = {
			path: `/${languageCode}/${routeItem.pathName}`,
			component: routeItem.component,
			meta: routeItem.meta,
			beforeEnter: routeItem.beforeEnter
		};
		if (routeItem.name !== undefined) {
			item.name = `${languageCode}.${routeItem.name}`;
		}
		if (routeItem.children !== undefined && routeItem.children.length > 0) {
			item.children = routeItem.children.map(x => convertChildRouteConfigToRoute(x, languageCode));
		}

		return item;
	});
	return items;
}

export function getAllRoutes(): Array<RouteConfig> {
	const routeConfigs = allRoutes.flatMap(routeConfig => convertRouteConfigToRoutes(routeConfig));
	// Add a catch-all error path
	routeConfigs.push({
		path: '*',
		name: 'error',
		component: Error
	});

	// Add a route to redirect the user to the homepage to the current language (or the default if not set) if they try to access the site root
	routeConfigs.unshift({
		path: "/",
		beforeEnter: redirectToHome
	});
	return routeConfigs;
}