import store from '@/store/index'; // path to your Vuex store


import '@/extensions/DateExtensions.ts';
import '@/extensions/StringExtensions.ts';
import { featureFlags } from '@/store/feature-flag.store';

export default class PlannedDeliveryHelper {
    public plannedDeliveryTo = "";
    public plannedDeliveryFrom = "";

    private order = store.getters.getOrder;
    private cloudOrder = store.state.order;

    /**
     * Construct a new plannedDeliveryHelper
     * 
     */
    constructor() {

    }

    /**
     * Calculate the planned Delivery Time String
     */
    public plannedDeliveryTimeString() {

        const useCloudEndpoints =
          featureFlags.BOOKING_RMD_USECLOUDENDPOINTS_DETAIL_VIEW_TIME_WINDOW;

        const {
          tourPlanningCode,
          deliveryDate,
          tourPlanningStatus,
          deliveryTimeWindowFrom,
          deliveryTimeWindowTo,
          plannedArrivalTime,
          tourPlanningStatusCompleted
        } = useCloudEndpoints
          ? {
              tourPlanningCode: this.cloudOrder.tourPlanningCode,
              deliveryDate: this.cloudOrder.deliveryDate,
              tourPlanningStatus: this.cloudOrder.tourPlanningStatus,
              deliveryTimeWindowFrom: this.cloudOrder.deliveryTimeWindowFrom ? this.cloudOrder.deliveryTimeWindowFrom.substr(11, 5): '',
              deliveryTimeWindowTo: this.cloudOrder.deliveryTimeWindowTo? this.cloudOrder.deliveryTimeWindowTo.substr(11, 5): '',
              plannedArrivalTime: this.cloudOrder.plannedArrivalTime,
              tourPlanningStatusCompleted: "TourPlanningCompleted"
            }
          : {
              tourPlanningCode: this.order.frameTour,
              deliveryDate: this.order.notifiedAppointment,
              tourPlanningStatus: this.order.fk_TourPlanningState,
              deliveryTimeWindowFrom: this.order.orderDateIntervalStart,
              deliveryTimeWindowTo: this.order.orderDateIntervalEnd,
              plannedArrivalTime: this.order.tourArrivalPlanned,
              tourPlanningStatusCompleted: 2
            };

        let isTourPlanning = false;
        if (tourPlanningCode != null && tourPlanningCode != "") {
          const parsedTourPlanningCode = parseInt(tourPlanningCode);
          if (parsedTourPlanningCode) {
            if (
              parsedTourPlanningCode >= 200 &&
              parsedTourPlanningCode <= 299
            ) {
                isTourPlanning = true;
            }
          }
        }

        if (!(deliveryDate != null && deliveryDate != "")) {
          return false;
        }

        const notifiedAppointmentDate = new Date(deliveryDate);
        const nowDate = new Date(Date.now());

        if (
          tourPlanningStatus == tourPlanningStatusCompleted &&
          (notifiedAppointmentDate.getDate() == nowDate.getDate() ||
            notifiedAppointmentDate.getDate() ==
              nowDate.addDays(1).getDate()) &&
          !isTourPlanning
        ) {
          this.plannedDeliveryFrom = this.GetTwoHourTimeSlot(plannedArrivalTime,
            deliveryTimeWindowFrom,
            deliveryTimeWindowTo
          );
          this.plannedDeliveryTo = this.GetTwoHourTimeSlot(plannedArrivalTime,
            deliveryTimeWindowFrom,
            deliveryTimeWindowTo,
            false
          );
        } else {
          const orderDateTimeStart = deliveryTimeWindowFrom.toDateTime();
          const orderDateTimeEnd = deliveryTimeWindowTo.toDateTime();

          const orderDateTimeStartHours = orderDateTimeStart.getHours();
          const orderDateTimeEndHours = orderDateTimeEnd.getHours();

          let from = orderDateTimeStart;
          let to = orderDateTimeEnd;

          if (orderDateTimeStartHours == 7 && orderDateTimeEndHours == 13) {
            from = new Date("1970-01-01T07:00:00");
            to = new Date("1970-01-01T14:00:00");
          } else if (
            orderDateTimeStartHours == 12 &&
            orderDateTimeEndHours == 18
          ) {
            from = new Date("1970-01-01T11:00:00");
            to = new Date("1970-01-01T18:00:00");
          } else if (
            orderDateTimeStartHours == 17 &&
            orderDateTimeEndHours == 21
          ) {
            from = new Date("1970-01-01T16:00:00");
            to = new Date("1970-01-01T21:00:00");
          } else if (
            orderDateTimeStartHours == 7 &&
            orderDateTimeEndHours == 18
          ) {
            from = new Date("1970-01-01T07:00:00");
            to = new Date("1970-01-01T18:00:00");
          }

          this.plannedDeliveryFrom = from.toLocaleTime();
          this.plannedDeliveryTo = to.toLocaleTime();
        }

        return true;
    }

    /**
     * Calculate the Two Hour Time Slot
     * @param start Start or End Time 
    */
    public GetTwoHourTimeSlot(plannedArrivalTime: any, dateStart: any, dateEnd: any,  start = true) {

        const defaultFromTime = new Date("1970-01-01T07:00:00");
        const defaultToTime = new Date("1970-01-01T19:00:00");

        const orderDateStart = dateStart;
        const orderDateEnd = dateEnd;

        const von = orderDateStart ? orderDateStart.toDateTime() : defaultFromTime;
        const bis = orderDateEnd ? orderDateEnd.toDateTime() : defaultToTime;

        const time = new Date(plannedArrivalTime);
        const timeMinutes = time.getMinutes();


        const beginn = time.addHours(-1).addMinutes(timeMinutes % 10 < 5 ? (-1 * timeMinutes) % 5 : 10 - timeMinutes % 10);
        const ende = beginn.addHours(2);

        const temp = beginn.getDateWithZeroTime();
        const minVon = temp.addTime(von);
        const maxBis = temp.addTime(bis);

        let result = null;

        if (beginn < minVon) {
            result = start ? minVon : minVon.addHours(2);
        } else if (ende > maxBis) {
            result = start ? maxBis.addHours(-2) : maxBis;
        } else {
            result = start ? beginn : ende;
        }

        return result.toLocaleTime();
    }

}
