















































































































































































































import Vue from "vue";
import HeroText from "@/components/modules/HeroText.vue";

export default Vue.extend({
  components: {
    HeroText,
  },
  data() {
    return {
      selectedTopic: {},
      options: [
        {
          title: this.$t("faq.topic.myData"),
          id: "myData",
        },
        {
          title: this.$t("faq.topic.myAppointment"),
          id: "myAppointment",
        },
        {
          title: this.$t("faq.topic.myService"),
          id: "myService",
        },
        {
          title: this.$t("faq.topic.ourProcess"),
          id: "ourProcess",
        },
        {
          title: this.$t("faq.topic.reclamation"),
          id: "reclamation",
        },
        {
          title: this.$t("faq.topic.contact"),
          id: "contact",
        },
      ],
    };
  },
  created() {
    // Make the first option the default
    if (this.options.length) this.selectedTopic = this.options[0];
  },
  methods: {
    changeTopic(option: IOption) {
      this.selectedTopic = option;
    },
  },
});
