











































import Vue from "vue";
import TheHeroLogin from "@/components/sections/TheHeroLogin.vue";
import i18n from "@/i18n";
import RhButton from "@/components/elements/RhButton.vue";

export default Vue.extend({
  data() {
    return {
      isGerman: i18n.locale === 'de',
    }
  },
  components: {
    RhButton,
    TheHeroLogin,
  },
});
