



























































import Vue from "vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import Calendar from "@/components/modules/Calendar.vue";
import Stepper from "@/components/modules/Stepper.vue";
import { Day } from "@/components/modules/types/Calendar";
import { getRouteName } from "@/routeConfig";

export default Vue.extend({
  components: {
    Calendar,
    Stepper,
  },
  async mounted()
  {
    await this.requestCustomerAppointments();
    this.appointmentsReady = true;
  },
  data() {
    return {
      selectedAppointmentId: "",
      selectedDay: "",
      appointmentsReady: false,
    };
  },
  computed: {
    ...mapGetters(["getAppointments", "getOrder", "getStepperValue"]),
    ...mapState([
      "appointmentError","bookableServices"
    ]),
  },
  methods: {
    getContactRoute() {
      return  { name: getRouteName("contact") };
    },
    selectAppointment(appointmentId: string) {
      this.selectedAppointmentId =
        this.selectedAppointmentId === appointmentId ? "" : appointmentId;

      this.SET_SELECTED_APPOINTMENT(this.selectedAppointmentId);
    },
    selectDay(day: Day) {
      if (day.appointments.length) this.selectedDay = day.date;
    },
    selectRoute() {
      if (this.bookableServices.length > 0) {
        this.$navigateTo("additionalServices");
        
      } else {
        this.$navigateTo("appointmentConfirmation");
      }
    },
    navigateBack() {
      if ((this.$store.state.service.hasAppointment || (!this.$store.state.service.hasAppointment && !this.$store.state.service.canMakeAppointment))) {
        this.$navigateTo('appointmentBase');
      }
      else {
        this.$navigateTo('order');
      }
    },
    ...mapMutations(["SET_SELECTED_APPOINTMENT"]),
    ...mapActions(["requestCustomerAppointments"]),
  },
});
