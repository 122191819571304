



















































































import Vue from "vue";
import { mapState, mapMutations, mapGetters } from "vuex";
import { getRouteName } from "@/routeConfig";
import { IStateHistory } from "@/store/types/IState";

interface NavigationObject {
  name: string;
  type: string;
  main: boolean;
  title: string;  
}

interface MobileFooterObject {
  title: string;
  name: string;
  url: string | undefined;
}

interface NavigationFunction {
  (type: string): NavigationObject[];
}

export default Vue.extend({
  data() {
    return {
      logoPath: getRouteName("order"),
      isOpen: false,
      homePage: getRouteName("startpage"),
      // Navigation array objects will have to change,
      // when pages are finished and can be linked to via router-link
      navigation: [
        {
          name: getRouteName("order"),
          type: "primary",
          main: true,
          title: this.$t("general.overview")          
        },
        {
          name: getRouteName("appointmentSelection"),
          type: "primary",
          title: this.$t("general.appointment")         
        },
        {
          name: getRouteName("tracking"),
          type: "primary",
          title: this.$t("general.shipmentTracking")          
        },
        //{ name: 'Zusatzleistungen', link: '#', type: 'primary' },
        //{ name: 'Auftragsbearbeitung', link: '#', type: 'primary' },
      ] as NavigationObject[],
      mobileFooter: [
        { title: this.$t("general.impressum"), name: getRouteName("imprint") },
        { title: this.$t("general.privacy"), name: getRouteName("privacy") },
        {
          title: this.$t("general.aboutUs"),
          url:
            "https://www.rhenus.group/de/customised-supply-chain/home-delivery/",
        },
        { title: this.$t("general.faq"), name: getRouteName("faq") },
      ] as MobileFooterObject[],
    };
  },

  computed: {
    getNavigationType(): NavigationFunction {
      return (type: string) =>
        type
          ? this.navigation.filter((nav) => nav.type === type)
          : this.navigation;
    },
    isSlim(): boolean {
      return !this.isLoggedIn;
    },
    ...mapState(["isLoggedIn", "service", "language"]),
   ...mapGetters(["allowTracking"])
  },
  watch: {
    language() {
      if (this.allowTracking) {
        this.navigation[2].title = this.$t("general.shipmentTracking").toString();
      } else {
        this.navigation[2].title = "";
      }
      this.navigation[0].title = this.$t("general.overview").toString();
      this.navigation[1].title = this.$t("general.appointment").toString();
      this.mobileFooter[0].title = this.$t("general.impressum").toString();
      this.mobileFooter[1].title =  this.$t("general.privacy").toString();
      this.mobileFooter[2].title = this.$t("general.aboutUs").toString();

      this.navigation[0].name = getRouteName("order");
      this.navigation[1].name = getRouteName("appointmentSelection");
      this.navigation[2].name = getRouteName("tracking");
      this.mobileFooter[0].name = getRouteName("imprint");
      this.mobileFooter[1].name = getRouteName("privacy");
      this.mobileFooter[3].name = getRouteName("faq");
      this.logoPath = getRouteName("order");
      this.homePage = getRouteName("startpage");
    },
    service() {
      this.navigation[1].name = getRouteName("appointmentSelection");
    },
  },
  methods: {
    ...mapMutations(["LOGOUT"]),
    isCancelled() {
        const cancelledStates = ['Storno','StornoBeiAbsortierung', 'StornoVorAbsortierung', 'StornoZl'];
     
        return this.service.stateHistoryList ? this.service.stateHistoryList.some((item: IStateHistory) => item.orderState !== null && cancelledStates.includes(item.orderState)) : false;
    },
    filterNavigation(arr: NavigationObject[]) {   
      if(this.isCancelled()){
        return arr.filter(nav => nav.main)
      }

      if (this.service.order === null || !this.service.order.isCompleted) {
        return arr.filter((nav) => nav.type == "primary");
      }

      return arr.filter((nav) => nav.main);
    },
  },
  mounted() {
    if (!this.allowTracking) {
      this.navigation[2].title = "";
    }
  },
});
